import React, { useState } from 'react'
import { env } from '../../config'
import axios from 'axios'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'

export interface Root {
    id: number
    title: string
    description: string
    image: Image
}

export interface Image {
    data: Data
}

export interface Data {
    id: number
    attributes: Attributes
}

export interface Attributes {
    name: string
    alternativeText: any
    caption: any
    width: number
    height: number
    formats: Formats
    hash: string
    ext: string
    mime: string
    size: number
    url: string
    previewUrl: any
    provider: string
    provider_metadata: any
    createdAt: string
    updatedAt: string
}

export interface Formats {
    thumbnail: Thumbnail
    medium: Medium
    small: Small
}

export interface Thumbnail {
    name: string
    hash: string
    ext: string
    mime: string
    path: any
    width: number
    height: number
    size: number
    url: string
}

export interface Medium {
    name: string
    hash: string
    ext: string
    mime: string
    path: any
    width: number
    height: number
    size: number
    url: string
}

export interface Small {
    name: string
    hash: string
    ext: string
    mime: string
    path: any
    width: number
    height: number
    size: number
    url: string
}


function ContactForm(props: { intro?: Root }) {
    const { intro } = props;


    interface formType {
        firstName: string;
        lastName: string;
        message: string;
        email: string;
    }
    const emailRegex = /^\S+@\S+\.\S+$/

    const defaultData = {
        firstName: "",
        lastName: "",
        message: "",
        email: ""
    }

    const [formData, setFormData] = useState<formType>(defaultData);
    const [loader, setLoader] = useState(false)

    const updateFormDetails = (
        e:
            | React.FormEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
        title: string
    ) => {

        let copyFormData = { ...formData, [title]: (e.target as HTMLInputElement).value }

        setFormData(copyFormData)
        console.log("copyFormData", copyFormData)
    };

    const areAnyPropertiesNull = (obj: formType) => Object.keys(obj).map((key) => {
        if (obj[key as keyof formType].length !== 0) {
            if (key === "email") {
                return emailRegex.test(obj[key as keyof formType]) ? null : "Invalid Email"
            }
            return null
        } else { return key }
    }).filter((node) => { return node !== null });



    const postMessage = async () => {
        setLoader(true)
        const emptyValueArray = areAnyPropertiesNull(formData)

        if (emptyValueArray.length === 0) {
            await axios
                .post(`${env}/api/contact-emails`, {
                    data: formData,
                })
                .then((response) => {
                    setLoader(false)

                    toast.success("Email Sent Successfully")
                    setFormData(defaultData)
                })
                .catch((e) => {
                    setLoader(false)

                    toast.error("Something Went Wrong, Please Try Again")

                });

        } else {
            emptyValueArray.forEach((node) => {
                if (typeof (node) === "string") {
                    setLoader(false)
                    toast.error(` ${node === "firstName" ? "First Name  cannot be empty" : node === "lastName" ? "Last Name  cannot be empty" : node === "Invalid Email" ? "Invalid Email, Please try again" : node[0].toUpperCase() + node.slice(1) + " Field cannot be empty"} `)

                }

            })
        }


    }

    const imageLoaded = () => {
        setImageLoading(false);
    };

    const [imageLoading, setImageLoading] = useState(true);



    return (
        <section className="px-5 md:px-10 py-14 bg-black grid xl:grid-cols-2 lg:h-max text-white">
            <div className='bg-[#040319] p-10 lg:p-5 xl:p-10 flex flex-col justify-center'>

                <h2 className="text-base sm:text-2xl md:text-4xl font-title">
                    {intro?.title}
                </h2>
                <p className=' font-body text-base lg:text-xl md:text-lg my-4'>
                    {intro?.description}
                </p>
                <img src={`${env}${intro?.image?.data?.attributes?.url}`} className='shrink-0 xl:hidden my-10' alt={intro?.image?.data?.attributes?.caption} />

                <div className=' grid grid-cols-2 gap-4 font-body'>
                    <div className="flex flex-col text-left col-span-2 md:col-span-1  ">
                        <label className="pt-1 pb-2 font-body">NAME </label>
                        <input
                            placeholder='First Name'
                            onChange={(e) => updateFormDetails(e, "firstName")}
                            value={formData?.firstName}
                            className="border border-[#81818c] bg-[#040319] p-2  shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                        ></input>

                    </div>
                    <div className="flex flex-col justify-end text-left col-span-2 md:col-span-1  ">
                        <label className="pt-1 pb-2 "> </label>
                        <input
                            placeholder='Last Name'
                            onChange={(e) => updateFormDetails(e, "lastName")}
                            value={formData?.lastName}
                            className="border border-[#81818c] bg-[#040319] p-2  shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                        ></input>

                    </div>
                    <div className="flex flex-col text-left col-span-2 md:col-span-2  ">
                        <label className="pt-1 pb-2 ">EMAIL </label>
                        <input
                            placeholder='Your Email'
                            onChange={(e) => updateFormDetails(e, "email")}
                            value={formData?.email}
                            className="border border-[#81818c] bg-[#040319] p-2  shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                        ></input>

                    </div>
                    <div className="flex flex-col text-left  col-span-2 ">
                        <label className="py-1">
                            Message <span className="text-gray-500">(Required)</span>
                        </label>
                        <textarea
                            placeholder='Example Text'
                            onChange={(e) => updateFormDetails(e, "message")}
                            value={formData?.message}
                            className="border min-h-[150px] border-[#81818c] bg-[#040319] p-2 rounded-sm shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]"
                        ></textarea>
                    </div>
                    <button onClick={postMessage} className={` ${loader ? "cursor-not-allowed w-[140px]" : "w-[100px]"} px-4 py-2 flex flex-row items-center gap-5 bg-white font-title duration-700 text-lg text-black `}>
                        SUBMIT
                        {loader &&
                            <svg className="animate-spin -ml-1 mr-3 h-6 w-6 shrink-0 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                    </button>
                </div>
            </div>
            <motion.img


                initial={{ height: `${intro?.image?.data?.attributes?.height}px`, opacity: 0, filter: `blur(20px)`, }}

                // style={{ height: imageLoading ? "6rem" : "auto" }}
                animate={{
                    height: imageLoading ? `${intro?.image?.data?.attributes?.height}px` : `${intro?.image?.data?.attributes?.height}px`,
                    opacity: imageLoading ? 0 : 1,
                    filter: `blur(0px)`,
                }}
                transition={{
                    height: { delay: 0, duration: 0.4 },
                    opacity: { delay: 0.5, duration: 0.4 }
                }}


                onLoad={imageLoaded}
                key={intro?.image?.data?.attributes?.url}

                src={`${env}${intro?.image?.data?.attributes?.url}`} className={`shrink-0 hidden xl:block m-auto w-[${intro?.image?.data?.attributes?.width}px] h-[${intro?.image?.data?.attributes?.height}px] `} alt={intro?.image?.data?.attributes?.caption} />
        </section >
    )
}

export default ContactForm