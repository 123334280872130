import React, { Suspense, useEffect, useState } from 'react'
import HeroSection from '../components/home/heroSection'
import axios from 'axios'
import { env } from '../config'
import Loader from '../components/home/Loader'
import { motion } from 'framer-motion'
import Footer from '../components/footer'
import Nav from '../components/nav'
const HomeIntro = React.lazy(() => import('../components/home/homeIntro'));
const HomeRegister = React.lazy(() => import('../components/home/homeRegister'));
const HomeDetails = React.lazy(() => import('../components/home/homeDetails'));
const HomeEnquiry = React.lazy(() => import('../components/home/homeEnquiry'));





function Home() {

    // const isPresent = useIsPresent();
    const [homePageData, setHomePageData] = useState<any>([])
    const url = "/api/home-page-content?populate=deep"

    const fetchApiData = () => {
        axios.get(`${env}${url}`)
            .then(function (response: { data: { data: { attributes: any } } }) {
                console.log(response.data.data.attributes);
                setHomePageData(response.data.data.attributes)
            })
            .catch((error: any) => {
                console.log("error", error);
            });
    }


    useEffect(() => {
        if (homePageData?.length === 0) {
            fetchApiData()
        }
    }, [])

    console.log("homePageData", homePageData)


    return (
        <>
            {homePageData.length !== 0 ?
                <Suspense fallback={<Loader />}>
                    <Nav />

                    <HeroSection url={homePageData?.hero?.backgroundImage?.data?.attributes?.url} />
                    <HomeIntro intro={homePageData?.intro} />
                    <HomeRegister register={homePageData?.register} />
                    <HomeDetails details={homePageData?.details} />
                    <HomeEnquiry enquiry={homePageData?.enquiry} />
                    <Footer />

                </Suspense >
                :
                <Loader />
            }

        </>

    )
}

export default Home