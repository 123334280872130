import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

function Icon() {

    const svgRef = useRef<SVGSVGElement | null>(null)
    const isInView = useInView(svgRef)

    const pathVariants = {
        hidden: {
            pathLength: 0,
            scale: 0,

        },
        visible: {

            scale: 1,
            transition: {
                type: "spring",
                damping: 10,
                mass: 1,
                stiffness: 100,
                duration: 1,
                ease: "easeInOut"
            }

        }
    }

    return (
        <a href="/">
            <motion.svg ref={svgRef} width="220" height="154" viewBox="0 0 220 154" className="overflow-visible " fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_445_7800)">
                    <motion.path initial={{ opacity: 0, pathLength: 0 }} animate={{ opacity: 1, pathLength: isInView ? 1 : 0 }} transition={{ duration: 4, ease: "easeInOut" }} d="M33.0296 151.066L70.7449 77.4585L71.2121 76.5466L70.745 75.6347L33.0291 2H35.9269L74.1098 76.5463L35.9271 151.066H33.0296Z" stroke="#4783B5" stroke-width="4" />
                    <motion.path initial={{ opacity: 0, pathLength: 0 }} animate={{ opacity: 1, pathLength: isInView ? 1 : 0 }} transition={{ duration: 4, ease: "easeInOut" }} d="M18.1602 151.066L55.7724 77.4565L56.2373 76.5466L55.7725 75.6367L18.1597 2H21.0601L59.1375 76.5463L21.0603 151.066H18.1602Z" stroke="#B6D191" stroke-width="4" />
                    <motion.path initial={{ opacity: 0, pathLength: 0 }} animate={{ opacity: 1, pathLength: isInView ? 1 : 0 }} transition={{ duration: 4, ease: "easeInOut" }} d="M3.2679 151.066L40.88 77.4565L41.345 76.5466L40.8802 75.6367L3.26738 2H6.16778L44.2452 76.5463L6.16798 151.066H3.2679Z" stroke="#FBBD99" stroke-width="4" />
                    <motion.path initial={{ opacity: 0, pathLength: 0 }} animate={{ opacity: 1, pathLength: isInView ? 1 : 0 }} transition={{ duration: 4, ease: "easeInOut" }} d="M188.722 59.8129V2H191.383V151.066H188.722V93.0634V91.0634H186.722H126.828H124.828V93.0634V151.066H122.167V2H124.828V59.8129V61.8129H126.828H186.722H188.722V59.8129ZM186.722 75.1077H188.722V73.1077V66.4468V64.4468H186.722H126.828H124.828V66.4468V73.1077V75.1077H126.828H186.722ZM186.722 88.4296H188.722V86.4296V79.7686V77.7686H186.722H126.828H124.828V79.7686V86.4296V88.4296H126.828H186.722ZM82.2289 2H84.8898V151.066H82.2289V2ZM95.5507 2H98.2116V151.066H95.5507V2ZM108.845 2H111.506V151.066H108.845V2ZM202.017 2H204.678V151.066H202.017V2ZM215.339 2H218V151.066H215.339V2Z" stroke="white" stroke-width="4" />
                </g>
                <defs>
                    <clipPath id="clip0_445_7800">
                        <motion.rect initial={{ opacity: 0, pathLength: 0 }} animate={{ opacity: 1, pathLength: isInView ? 1 : 0 }} transition={{ duration: 4, ease: "easeInOut" }} width="220" height="153.066" fill="white" />
                    </clipPath>
                </defs>
            </motion.svg>

        </a>

    );
}

export default Icon;
