import React, { Suspense, useEffect, useState } from 'react'
import axios from 'axios'
import { env } from '../config'
import { motion } from 'framer-motion'
import Footer from '../components/footer'
import Loader from '../components/home/Loader'
import Nav from '../components/nav'
const AboutHero = React.lazy(() => import('../components/about/aboutHero'));
const HomeSponsor = React.lazy(() => import('../components/home/homeSponsor'));
const AboutMissionValues = React.lazy(() => import('../components/about/aboutMissionValues'));



function About() {

    const [aboutPageData, setAboutPageData] = useState<any>([])
    const url = "/api/about-page-content?populate=deep"

    const [imgsLoaded, setImgsLoaded] = useState(false)


    // useEffect(() => {


    //     let backgroundImageCopy = backgroundImage
    //     console.log("backgroundImage", backgroundImageCopy)
    //     backgroundImageCopy.src = `${env}${aboutPageData?.hero?.image?.data?.attributes?.url}`


    //     backgroundImage.onload = () => {
    //         setBackgroundImage(backgroundImageCopy)
    //         setImgsLoaded(imgsLoaded => !imgsLoaded)


    //     };

    //     backgroundImage.onerror = (err) => {

    //         console.error('Error loading background image:', err);

    //     };



    //     return () => {

    //         // Clean up to prevent memory leaks

    //         backgroundImage.onload = null;

    //         backgroundImage.onerror = null;

    //     };

    // }, [aboutPageData])

    const fetchApiData = () => {
        axios.get(`${env}${url}`)
            .then(function (response: { data: { data: { attributes: any } } }) {
                console.log(response.data.data.attributes);
                setAboutPageData(response.data.data.attributes)



            })
            .catch((error: any) => {
                console.log("error", error);
            });
    }


    useEffect(() => {
        if (aboutPageData?.length === 0) {
            fetchApiData()
        }



    }, [])


    const pageMotion = {
        exit: {
            y: '-100vh',
            transition: {
                duration: 2,
                ease: 'easeInOut',
            },
        },
        initial: {
            y: "-60vh",
            opacity: 0,
            rotate: "360deg",
            transition: {
                duration: 1,
                ease: 'easeInOut',
            },

        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1.5,
                ease: 'easeInOut',
            },
        }
    }






    console.log("aboutPageData", aboutPageData)


    return (
        aboutPageData?.length !== 0 ?
            <Suspense fallback={<Loader />}>
                <motion.div
                // animate="animate"
                // initial="initial"
                // variants={pageMotion}
                // exit="exit"
                >
                    <Nav />

                    <AboutHero hero={aboutPageData?.hero} />
                    <AboutMissionValues missionValues={aboutPageData?.mission} />
                    <HomeSponsor sponsor={aboutPageData?.sponsor} />
                    <Footer />


                </motion.div >

            </Suspense>
            :
            <Loader />

    )
}

export default About