import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/home';
import { AnimatePresence } from 'framer-motion';
import PrivacyPolicy from './pages/privacypolicy';
import Test from './components/test';
import About from './pages/about';
import EventDetails from './components/eventDetails/eventDetails';
import Contact from './components/contactUs/contact';
import { Toaster } from 'react-hot-toast';


function App() {

  return (
    <AnimatePresence mode="wait">
      <Toaster />


      <Router>
        <Routes>
          <Route path="/" key={window?.location?.pathname} element={<Home />}></Route>
          <Route path="/home" key={window?.location?.pathname} element={<Home />}></Route>
          <Route path="/privacy-policy" key={window?.location?.pathname} element={<PrivacyPolicy />}></Route>
          <Route path="/about" key={window?.location?.pathname} element={<About />}></Route>
          <Route path="/event-details" key={window?.location?.pathname} element={<EventDetails />}></Route>
          <Route path="/contact-us" key={window?.location?.pathname} element={<Contact />}></Route>
          {/* <Route path="/test" key={window?.location?.pathname} element={<Test />}></Route> */}
        </Routes>

      </Router >

    </AnimatePresence>

  );
}

export default App;
