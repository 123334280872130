import React from 'react'

function FooterK() {
    return (
        <svg className='  hidden sm:block absolute right-0 top-[30%] lg:top-0 h-[350px] pointer-events-none' width="357" height="495" viewBox="0 0 357 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1" clip-path="url(#clip0_426_1062)">
                <path d="M366.811 0H345.285V495H366.811V0Z" fill="white" />
                <path d="M323.847 0H302.322V495H323.847V0Z" fill="white" />
                <path d="M280.796 0H259.27V495H280.796V0Z" fill="white" />
                <path d="M120.053 495H96.1653L222.869 247.544L96.1653 0H120.053L246.757 247.544L120.053 495Z" fill="#4783B5" />
                <path d="M72.0147 495H48.1265L174.48 247.544L48.1265 0H72.0147L198.368 247.544L72.0147 495Z" fill="#B6D191" />
                <path d="M23.8882 495H0L126.354 247.544L0 0H23.8882L150.242 247.544L23.8882 495Z" fill="#FBBD99" />
                <path d="M126.354 247.544L0 0H23.8882L150.242 247.544H126.354Z" fill="#FBBD99" />
                <path d="M174.48 247.544L48.1265 0H72.0147L198.368 247.544H174.48Z" fill="#B6D191" />
                <path d="M222.694 247.544L96.3403 0H120.229L246.582 247.544H222.694Z" fill="#4783B5" />
                <path d="M259.27 0H280.796V495H259.27V0ZM302.322 0H323.847V495H302.322V0ZM345.285 0H366.811V495H345.285V0ZM603.418 0H624.944V495H603.418V300.958H409.862V495H388.337V0H409.862V193.429H603.418V0ZM603.418 236.423V214.882H409.862V236.423H603.418ZM603.418 279.505V257.964H409.862V279.505H603.418ZM646.382 0H667.908V495H646.382V0ZM689.433 0H710.959V495H689.433V0Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_426_1062">
                    <rect width="358.344" height="495" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FooterK