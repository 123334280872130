import { motion } from 'framer-motion'
import React from 'react'

function Loader() {
    const wrapperVariants = {

        initial: {

            clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',

            transition: { duration: .4 }

        },

        animate: {

            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',

            transition: { duration: .4, }

        },

        exit: {

            clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',

            transition: {
                duration: 1,
                ease: [0.87, 0, 0.13, 1],
            },

        }

    }
    const blackBox = {
        initial: {
            y: "100vh",
            duration: 6


        },
        animate: {
            y: 0,
            transition: {
                duration: 6,
                ease: [0.87, 0, 0.13, 1],
            }

        },
        exit: {
            y: "100vh"
            , transition: {
                duration: 6,
                ease: [0.87, 0, 0.13, 1],
            }
        }
    }


    return (
        <>
            <motion.div
                // variants={blackBox}
                // initial="initial"
                // animate="animate"
                // exit="exit"
                // initial={{ height: "100vh" }}
                // animate={{ height: 0 }}
                // exit={{ opacity: 0 }}
                // transition={{
                //     duration: 2,
                //     ease: [0.87, 0, 0.13, 1],
                // }}
                layoutId="homeScreen"
                className='w-screen h-screen bg-background font-title fixed flex justify-center items-center'>
                <div className='loader font-title'>
                </div>
            </motion.div >


        </>


    )
}

export default Loader