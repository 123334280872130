import React, { useEffect, useRef } from 'react'
import Icon from './icon'
import HeroTitle from "./heroTitle"
import { env } from '../../config'
import { motion } from "framer-motion"



function HeroSection(props: { url: string }) {
    const { url } = props;

    const heroRef = useRef<HTMLElement | null>(null)




    return (<>
        <motion.header
            ref={heroRef}
            layoutId="homeScreen"
            style={{ backgroundImage: `url(${env}${url})` }}
            className=' relative max-h-screen h-screen duration-75 w-screen px-5 z-20  md:px-20 py-7 flex flex-col  justify-center items-center bg-no-repeat bg-cover  bg-background '>
            {/* <div className=' h-12 w-12 md:h-16 md:w-16 lg:w-24 lg:h-24 absolute left-9 top-0 md:relative  md:top-0 md:left-0  md:mr-auto'>
                <Icon />
            </div> */}
            <HeroTitle />

            {/* <img src={`${env}${url}`} className='absolute w-screen h-screen z-30' alt="Hero" /> */}
        </motion.header>

    </>


    )
}

export default HeroSection;