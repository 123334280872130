import React from 'react'

function FooterH() {
    return (
        <svg className=' hidden sm:block absolute left-0 top-0 h-[350px] pointer-events-none' width="410" height="495" viewBox="0 0 410 495" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1" clip-path="url(#clip0_426_1075)">
                <path d="M108.942 0H87.4187V495H108.942V0Z" fill="white" />
                <path d="M65.8957 0H44.3728V495H65.8957V0Z" fill="white" />
                <path d="M22.9375 0H1.41455V495H22.9375V0Z" fill="white" />
                <path d="M-41.6313 0H-20.1084V495H-41.6313V0ZM1.41449 0H22.9374V495H1.41449V0ZM44.3728 0H65.8957V495H44.3728V0ZM302.473 0H323.996V495H302.473V300.958H108.942V495H87.4187V0H108.942V193.429H302.473V0ZM302.473 236.423V214.882H108.942V236.423H302.473ZM302.473 279.505V257.964H108.942V279.505H302.473ZM345.431 0H366.954V495H345.431V0ZM388.477 0H410V495H388.477V0Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_426_1075">
                    <rect width="410" height="495" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FooterH