import axios from 'axios'
import React, { Suspense, useEffect, useState } from 'react'
import { env } from '../../config'
import { motion, useScroll } from 'framer-motion'
import Footer from '../footer'
import Nav from '../nav'
import Loader from '../home/Loader'
const EventDetailsHero = React.lazy(() => import('../eventDetails/eventDetailsHero'));
const EventDetailsSection = React.lazy(() => import('../eventDetails/eventDetailsSection'));
const EventDetailsScrollSection = React.lazy(() => import('../eventDetails/eventDetailsScrollSection'));






function EventDetails() {
    const [eventDetailsPageData, setEventDetailsPageData] = useState<any>([])
    const url = "/api/event-details-page-content?populate=deep"




    const fetchApiData = () => {
        axios.get(`${env}${url}`)
            .then(function (response: { data: { data: { attributes: any } } }) {
                console.log(response.data.data.attributes);
                setEventDetailsPageData(response.data.data.attributes)



            })
            .catch((error: any) => {
                console.log("error", error);
            });
    }

    // const { scrollYProgress } = useScroll();
    // const scaleX = scrollYProgress

    useEffect(() => {
        if (eventDetailsPageData?.length === 0) {
            fetchApiData()
        }



    }, [])
    return (
        eventDetailsPageData?.length !== 0 ?
            <Suspense fallback={<Loader />}>
                <Nav />
                {/* <motion.div style={{ scaleX }} className='h-3 w-screen origin-[0%] top-10 z-[100022222] fixed bg-red-500'></motion.div> */}
                <EventDetailsHero hero={eventDetailsPageData?.hero} />
                <EventDetailsSection details={eventDetailsPageData?.details} />
                <EventDetailsScrollSection data={eventDetailsPageData?.scrollSection} />
                <Footer />
            </Suspense>
            : <Loader />

    )
}

export default EventDetails