import React from 'react'
import Icon from './home/icon'
import FooterH from './footerH'
import FooterK from './footerK'
import qr from "../Assets/qr.png"
import { motion } from 'framer-motion'

function Footer() {

    const footerItems = [{ title: "Home", link: "/home" }, { title: "About Us", link: "/about" }, { title: "Event Details", link: "/event-details" }, { title: "Contact Us", link: "/contact-us" }, { title: "Privacy Policy", link: "/privacy-policy" }
    ]

    const footerMotion = {
        initial: {
            y: 100,
            opacity: 0

        },
        visible: {
            y: 0,
            opacity: 1,

            transition: {
                duration: 2
            }
        }
    }

    const containerVars = {
        initial: {
            transition: {
                delayChildren: 0.4,

                staggerChildren: 0.10
            }
        },
        animate: {
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.10
            }
        }
    }
    const mobileLinkVars = {
        initial: {
            y: "30vh",
            transition: {
                duration: 0.7,
                ease: [0.37, 0, 0.63, 1],
            },
        },
        open: {
            y: 0,
            transition: {
                ease: [0, 0.55, 0.45, 1],
                duration: 0.7,
            },
        },
        exit: {
            y: "30vh",
            transition: {
                duration: 0.6,
                ease: [0.37, 0, 0.63, 1],
            },
        },
    };
    // variants={footerMotion} initial="initial" animate="visible"


    return (
        <motion.footer key="FOOTER" className='relative md:h-[350px] max-w-screen overflow-x-hidden bg-background'>
            <FooterH />
            <FooterK />
            <motion.div key="footerCOntainer" variants={containerVars} initial="initial" animate="animate" exit="exit" className=' text-white w-screen font-title px-5 md:px-20 py-14 flex flex-col gap-5 md:flex-row justify-around items-center'>
                <div className='xl:w-40 xl:h-40 md:w-24 md:h-auto w-24'>
                    <Icon />
                </div>
                {footerItems?.map((node) => {
                    return (
                        <motion.div key={node?.link} className="overflow-hidden">
                            <motion.a href={node?.link} variants={mobileLinkVars} exit="exit" initial="initial" animate="open" className='lg:text-lg md:text-base overflow-hidden font-body  a-4'>
                                {node?.title}
                            </motion.a>
                        </motion.div>
                    )
                })}
                <img className='xl:w-40 xl:h-40 md:w-24 md:h-24' src={qr} alt="QR" />
            </motion.div>
            <div className=' text-sm text-white text-center font-body flex p-3 justify-center items-center bg-background'>
                © 2024 Copyright kochi-hackathon . All rights reserved
            </div>
        </motion.footer>

    )
}

export default Footer