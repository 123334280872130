import React, { useEffect, useRef, useState } from 'react'
import Icon from './home/icon'
import { AnimatePresence, motion, useScroll } from 'framer-motion'
import { inherits } from 'util'

function Nav() {

    console.log("loation", window?.location.pathname)

    const ref = useRef<HTMLDivElement | null>(null)


    // useEffect(() => {
    //     window.addEventListener('scroll', function () {
    //         // Calculate the scroll position relative to the page height
    //         const scrollPosition = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
    //         if (ref?.current) {
    //             if (scrollPosition <= 0.3) {
    //                 ref.current.style.display = 'none';
    //             } else {
    //                 ref.current.style.display = 'flex';
    //             }
    //         }
    //     });

    // }, [])


    const mobileNavVariants = {
        initial: {
            translateY: "-100vh"
        }
        , visible: {
            translateY: 0,
            transition: {
                durartion: 1,
                ease: [0.12, 0, 0.39, 0]
            }

        },
        exit: {
            translateY: "-100vh",
            scaleY: 0,
            transition: {
                delay: 0.7,

                durartion: 2,
                ease: [0.22, 1, 0.36, 1]

            }
        }
    }

    const mobileLinkVars = {
        initial: {
            y: "30vh",
            transition: {
                duration: 0.7,
                ease: [0.37, 0, 0.63, 1],
            },
        },
        open: {
            y: 0,
            transition: {
                ease: [0, 0.55, 0.45, 1],
                duration: 0.7,
            },
        },
        exit: {
            y: "30vh",
            transition: {
                duration: 0.6,
                ease: [0.37, 0, 0.63, 1],
            },
        },
    };

    const containerVars = {
        initial: {
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.10
            }
        },
        animate: {
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.9
            }
        }
    }

    const [showModal, setshowModal] = useState(false)

    const navItems = [{ title: "Home", route: "/" }, { title: "About", route: "/about" }, { title: "Event Details", route: "/event-details" }, { title: "Contact Us", route: "/contact-us" }]
    return (
        <AnimatePresence mode="wait">
            <motion.div ref={ref} key="pcNav" className={`  hidden ${window?.location?.pathname === "/privacy-policy" ? "hidden" : window?.location?.pathname === "/" ? "hidden" : window?.location?.pathname === "/home" ? "hidden" : "md:flex"} top-0 w-screen px-5 md:px-20 py-7 z-[99]  bg-black justify-evenly  items-center font-body text-white`}>
                <div className=' h-12 w-12 md:h-16 md:w-16 lg:w-24 lg:h-24 flex items-center justify-center'>
                    <Icon />
                </div>
                {navItems?.map((node) => {
                    return (
                        <a key={node?.title} href={node?.route}>
                            {node?.title}
                        </a>
                    )
                })}
            </motion.div>

            {showModal ?
                <motion.div key="mobileNav" variants={mobileNavVariants} initial="initial" animate="visible" exit="exit" className={`fixed origin-top ${window?.location?.pathname === "/privacy-policy" && "hidden"} ${showModal ? " top-0 left-0  w-screen h-screen" : "top-10 right-10 w-[300px] "}  md:hidden   z-[100] flex flex-col text-white bg-black rounded-[5px]`}>
                    <motion.div variants={containerVars} initial="initial" animate="animate" key="mobileDivInner" className=' flex flex-col w-full  h-full justify-center items-center relative bg-black'>
                        <div className='fixed top-10 w-full flex justify-between px-10 items-center'>
                            <div className=' h-12 w-12 md:h-16 md:w-16 lg:w-24 lg:h-24  top-10 left-10 flex items-center z-[100]  justify-center'>
                                <Icon />
                            </div>
                            <svg role="button" onClick={(e) => { e.preventDefault(); setshowModal(false) }} className={`w-4 h-4 stroke-white fill-white  duration-300 cursor-pointer`} width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className='fill-white stroke-white' d="M1.32309 21.6595C0.69028 22.2923 0.660146 23.4223 1.33816 24.0853C2.00111 24.7482 3.13113 24.7332 3.76394 24.1004L12.9849 14.8643L22.221 24.1004C22.8689 24.7482 23.9838 24.7482 24.6468 24.0853C25.2946 23.4073 25.3097 22.3074 24.6468 21.6595L15.4258 12.4235L24.6468 3.20248C25.3097 2.5546 25.3097 1.43964 24.6468 0.776697C23.9687 0.128818 22.8689 0.113751 22.221 0.76163L12.9849 9.99768L3.76394 0.76163C3.13113 0.128818 1.98604 0.0986839 1.33816 0.776697C0.675213 1.43964 0.69028 2.56967 1.32309 3.20248L10.5591 12.4235L1.32309 21.6595Z" fill="#FBBD99" />
                            </svg>

                        </div>
                        {navItems?.map((node) => {
                            return (
                                <motion.div className="overflow-hidden">
                                    <motion.a variants={mobileLinkVars} exit="exit" initial="initial" animate="open" key={node?.title} href={node?.route} className='p-5 font-title text-4xl flex justify-between '>
                                        {node?.title}
                                    </motion.a>
                                </motion.div>

                            )
                        })}
                    </motion.div>
                </motion.div>
                :
                <motion.button key="mobileCLoseButton" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3, ease: "backIn" }} onClick={() => { setshowModal(true) }} className='flex flex-col w-max md:hidden fixed top-10 z-[700]  gap-1 right-10'>
                    <span className='w-6 h-1 bg-white'></span>
                    <span className='w-6 h-1 bg-white'></span>
                    <span className='w-6 h-1 bg-white'></span>
                </motion.button>

            }

        </AnimatePresence>
    )
}

export default Nav