import React, { useEffect, useState } from 'react'
import ContactForm from './contactForm'
import Footer from '../footer'
import axios from 'axios'
import { env } from '../../config'
import Nav from '../nav'

function Contact() {

    const [contactPageData, setContactPageData] = useState<any>([])
    const url = "/api/contact-page-content?populate=deep"

    const fetchApiData = () => {
        axios.get(`${env}${url}`)
            .then(function (response: { data: { data: { attributes: any } } }) {
                console.log(response.data.data.attributes);
                setContactPageData(response.data.data.attributes)



            })
            .catch((error: any) => {
                console.log("error", error);
            });
    }


    useEffect(() => {
        if (contactPageData?.length === 0) {
            fetchApiData()
        }



    }, [])
    return (
        <>
            <Nav />

            <ContactForm intro={contactPageData?.intro} />
            <Footer />
        </>
    )
}

export default Contact